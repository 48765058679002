import { useFormContext, Controller } from "react-hook-form";

import { Label, Input, FormFeedback, Row, Col, InputGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TextInput({
  label,
  color = "primary",
  icon,
  name,
  type,
  twoLines = false,
  ...props
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const isTextarea = type === "textarea";

  return (
    <Row>
      <Col md={isTextarea || twoLines ? "12" : "4"} sm="12">
        <Label className="form-label" for={name}>
          <h6>{label}</h6>
        </Label>
      </Col>
      <Col md={isTextarea || twoLines ? "12" : "8"} sm="12">
        <Controller
          id={name}
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return icon ? (
              <InputGroup>
                {icon && (
                  <span className={`p-inputgroup-addon text-${color}`}>
                    <FontAwesomeIcon icon={icon} />
                  </span>
                )}
                <Input
                  placeholder={label}
                  invalid={error && true}
                  {...field}
                  prefix="XY"
                  {...props}
                  type={type}
                />
                {error && <FormFeedback>{error?.message}</FormFeedback>}
              </InputGroup>
            ) : (
              <>
                <Input
                  placeholder={label}
                  invalid={error && true}
                  {...field}
                  prefix="XY"
                  {...props}
                />
                {error && <FormFeedback>{error?.message}</FormFeedback>}
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
}

export default TextInput;
