import { mainApi } from "../mainApi";

export const reasonApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getReasons: builder.query({
      query: (filters) => ({
        url: `/leftbehind/reason/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "leftBehindReasons", id: "LIST" }],
    }),
    addReason: builder.mutation({
      query: (reasonData) => ({
        url: `/leftbehind/reason`,
        method: "POST",
        body: reasonData,
      }),
      invalidatesTags: [{ type: "leftBehindReasons", id: "LIST" }],
    }),
    getReasonList: builder.query({
      query: () => ({
        url: `/leftbehind/reason/list`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReasonsQuery,
  useAddReasonMutation,
  useGetReasonListQuery,
} = reasonApi;
