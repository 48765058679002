import { formatDateOnly, formatDateTime } from "@src/utility/Utils";
import { Calendar } from "primereact/calendar";
import { format } from "date-fns";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, UncontrolledTooltip } from "reactstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";

const itemColumns = () => {
  const dateFilterTemplate = (options) => (
    <Calendar
      value={options.value ? new Date(options.value) : null}
      onChange={(e) =>
        options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index)
      }
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );

  const dateOnlyBodyTemplate = (rowData, field) =>
    formatDateOnly(new Date(rowData[field]));

  const dateTimeBodyTemplate = (rowData, field) =>
    formatDateTime(new Date(rowData[field]));

  const actionsBodyTemplate = (row) => {
    return (
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <a
          style={{ padding: ".515rem .536rem" }}
          id="details"
          outline
          className="btn  btn-icon rounded-circle btn-outline-dark"
          color="primary"
          href={`/baggage-management/found-items/list/${row.id}`}
          target="_self"
        >
          {" "}
          <FontAwesomeIcon icon={faEye} className="font-medium-3" />
        </a>
        <UncontrolledTooltip target="details" placement="top">
          Details
        </UncontrolledTooltip>
        <Button
          style={{ padding: ".515rem .536rem" }}
          id="update"
          className="btn-icon rounded-circle ms-1"
          outline
          color="dark"
          href={`/baggage-management/found-items/list/${row.id}/update`}
        >
          <span className="text-uppercase">
            <FontAwesomeIcon icon={faEdit} className="font-medium-3" />
          </span>
        </Button>
        <UncontrolledTooltip target="update" placement="top">
          Update
        </UncontrolledTooltip>
      </div>
    );
  };

  const Statuses = ["pending", "delivered", "forwarded", "destroyed"];

  const getStatusSeverity = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "delivered":
        return "success";
      case "destroyed":
        return "danger";
      default:
        return "info"; //forwarded
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status}
        severity={getStatusSeverity(rowData.status)}
        className="text-capitalize"
      />
    );
  };

  const statusItemTemplate = (option) => {
    return (
      <span className="text-capitalize">
        <Tag value={option} severity={getStatusSeverity(option)} />
      </span>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={Statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  return [
    {
      header: "Record Serial Number",
      filter: true,
      field: "id",
      sortable: false,
      bodyStyle: { width: "10%" },
    },
    {
      header: "Flight Number",
      filter: true,
      field: "flightNumber",
      sortable: false,
      bodyStyle: { width: "10%" },
    },
    {
      header: "Flight Date",
      filter: true,
      field: "flightDate",
      sortable: false,
      dataType: "date",
      body: (rowData) => dateOnlyBodyTemplate(rowData, "flightDate"),
      filterElement: dateFilterTemplate,
      filterField: "foundItem.flightDate",
    },
    {
      header: "Received Date",
      filter: true,
      field: "receivedDate",
      sortable: false,
      dataType: "date",
      body: (rowData) => dateOnlyBodyTemplate(rowData, "receivedDate"),
      filterElement: dateFilterTemplate,
      filterField: "foundItem.receivedDate",
    },
    {
      header: "From Sector",
      filter: true,
      field: "fromSector",
      sortable: false,
    },
    {
      header: "To Sector",
      filter: true,
      field: "toSector",
      sortable: false,
    },
    {
      header: "Status",
      filter: true,
      field: "status",
      sortable: false,
      body: statusBodyTemplate,
      filterElement: statusFilterTemplate,
      showFilterMatchModes: false,
      bodyStyle: { width: "10%", textAlign: "center", overflow: "visible" },
      filterField: "foundItem.status",
    },
    {
      header: "Created By",
      filter: true,
      field: "createdBy",
      sortable: false,
    },
    {
      header: (
        <>
          Created Time<span className="font-small-2 text-muted"> (UTC)</span>
        </>
      ),
      filter: true,
      field: "createdOnUTC",
      sortable: false,
      dataType: "date",
      body: (rowData) => dateTimeBodyTemplate(rowData, "createdOnUTC"),
      filterElement: dateFilterTemplate,
      filterField: "foundItem.createdOnUTC.date",
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      headerStyle: { textAlign: "center" },
      body: (rowData) => actionsBodyTemplate(rowData),
    },
  ];
};

export default itemColumns;
