import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

async function ConfirmSweetAlert({
  title,
  text,
  html,
  icon,
  confirmButtonClasses = "btn btn-success",
  denyButtonClasses = "btn btn-warning  ms-1",
  confirmButtonText,
  cancelButtonClasses,
  cancelButtonText,
  denyButtonText,
  animation = "tada",
  onConfirm = () => {},
  onDeny = () => {},
  showConfirmButton = true,
  showCancelButton = false,
  showDenyButton = false,
}) {
  const result = await MySwal.fire({
    title: title,
    text: text,
    html: html,
    icon: icon,
    confirmButtonText: confirmButtonText,
    showCloseButton: true,
    showCancelButton: showCancelButton,
    cancelButtonText: cancelButtonText,
    showDenyButton: showDenyButton,
    denyButtonText: denyButtonText,
    customClass: {
      confirmButton: confirmButtonClasses,
      cancelButton: cancelButtonClasses,
      denyButton: denyButtonClasses,
    },
    showClass: {
      popup: `animate__animated animate__${animation}`,
    },
    buttonsStyling: false,
    showConfirmButton: showConfirmButton,
  });
  if (result.isConfirmed) {
    onConfirm();
  } else if (result.isDenied) {
    onDeny();
  }
}

export default ConfirmSweetAlert;
