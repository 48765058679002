import ItemTypes from ".";

const ItemTypesRoutes = [
  {
    path: "/found-items/item-types",
    index: true,
    element: <ItemTypes />,
  },
];

export default ItemTypesRoutes;
