import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useReverseOrderMutation } from "@src/redux/stock/order";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import { formatDateTime } from "@src/utility/Utils";

const ReverseModal = ({ visible, toggle, orderData }) => {
  const [reverseOrder] = useReverseOrderMutation();
  const reverseOrderSchema = yup.object().shape({
    reverseReason: yup.string().required(),
  });

  const formMethods = useForm({
    resolver: yupResolver(reverseOrderSchema),
    mode: "onSubmit",
    defaultValues: {
      reverseReason: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        reverseReason: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await reverseOrder({
      id: orderData.id,
      reverseReason: formData.reverseReason,
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Order reversed successfully!",
        });
        toggle(false);
      });
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Reverse Order</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                {" "}
                <Row>
                  <Col tag="dt" sm="4" className="fw-bolder mb-1">
                    Number:
                  </Col>
                  <Col tag="dd" sm="8" className="mb-1">
                    {orderData?.id}
                  </Col>
                  <Col tag="dt" sm="4" className="fw-bolder mb-1">
                    Station:
                  </Col>
                  <Col tag="dd" sm="8" className="mb-1">
                    {orderData?.station}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {" "}
                <Row>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Total Weight:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {orderData?.totalWeight}
                  </Col>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Created On (Utc):
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {orderData?.createdOnUtc &&
                      formatDateTime(new Date(orderData?.createdOnUtc))}
                  </Col>
                </Row>
              </Col>
              <hr />
              <Col md={12}>
                <FormGroup>
                  <TextInput
                    twoLines={true}
                    label="Reverse Reason"
                    name="reverseReason"
                    icon={faTag}
                    type="textarea"
                    rows="5"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ReverseModal;
