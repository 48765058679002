import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import { useGetLogsQuery, useExportLogsMutation } from "@src/redux/stock/log";
import LogsColumns from "./columns";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Export from "../../common/Export";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { Column } from "primereact/column";
import { useGetQuantityTypeListQuery } from "@src/redux/stock/quantityType";
import { Dropdown } from "primereact/dropdown";

const Logs = () => {
  const { itemId } = useParams();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    orderId: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    operationType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    station: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    stockItemName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    quantityTypeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    quantity: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "createdOnUtc.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    createdBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const appendItemIdFilter = (itemId, currentFilters) => {
    const itemIdFilter = {
      logic: "and",
      filters: [
        {
          field: "stockItemId",
          operator: "eq",
          value: itemId,
        },
      ],
    };

    let updatedFilters = JSON.parse(JSON.stringify(currentFilters));

    if (updatedFilters.advancedFilter) {
      updatedFilters.advancedFilter.filters.push(itemIdFilter);
    } else {
      updatedFilters.advancedFilter = {
        logic: "or",
        filters: [itemIdFilter],
      };
    }

    return updatedFilters;
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data: quantityTypes } = useGetQuantityTypeListQuery();

  const { data, isLoading } = useGetLogsQuery(
    itemId ? appendItemIdFilter(itemId, filters) : filters
  );

  const [exportLogs, { isLoading: isLoadingExportedLogs }] =
    useExportLogsMutation();

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => (
    <Export
      filters={filters}
      exportFunction={exportLogs}
      exportedEntity={"histories"}
      isLoading={isLoadingExportedLogs}
    />
  );

  useEffect(() => {
    if (itemId) {
      setFilters(appendItemIdFilter(itemId, filters));
    }
  }, [itemId]);

  const operationTypeItemTemplate = (option) => {
    return <span className="text-capitalize">{option}</span>;
  };
  const operationTypeFilterTemplate = (options, quantityTypes) => {
    return (
      <Dropdown
        value={options.value}
        options={quantityTypes?.map((item) => item.name)}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={operationTypeItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  return (
    <>
      <BreadCrumbs pageName="historyList" pageTitle="History List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={LogsColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Histories Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            tableState={filters}
          >
            <Column
              field="quantityTypeName"
              filter={true}
              header="Quantity Type"
              showFilterMatchModes={false}
              filterElement={(options) =>
                operationTypeFilterTemplate(options, quantityTypes)
              }
              bodyStyle={{
                textAlign: "center",
                overflow: "visible",
                width: "10%",
              }}
            />
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default Logs;
