import { useNavigate } from "react-router-dom";
import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetOrdersQuery,
  useExportOrdersMutation,
} from "@src/redux/stock/order";
import OrderColumns from "./columns";
import { useState, useCallback, useMemo } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import ApproveModal from "./ApproveModal";
import RejectModal from "./RejectModal";
import ReverseModal from "./ReverseModal";
import Export from "../../common/Export";

const Orders = () => {
  const navigate = useNavigate();

  const [approveModalVisibility, setApproveModalVisibility] = useState(false);
  const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
  const [reverseModalVisibility, setReverseModalVisibility] = useState(false);

  const [orderData, setOrderData] = useState(null);

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    station: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    totalWeight: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "orderTagType.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "createdOnUtc.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    comments: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetOrdersQuery(filters);
  const [exportOrders, { isLoading: isLoadingExportedOrders }] =
    useExportOrdersMutation();

  const clearFilters = useCallback(() => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  }, [initialDtFilters]);

  const handleApproveOrderClick = useCallback((order) => {
    setOrderData(order);
    setApproveModalVisibility(true);
  }, []);

  const handleRejectOrderClick = useCallback((order) => {
    setOrderData(order);
    setRejectModalVisibility(true);
  }, []);

  const handleReverseOrderClick = useCallback((order) => {
    setOrderData(order);
    setReverseModalVisibility(true);
  }, []);

  const getAdvancedFilters = useCallback(() => {
    const today = new Date();
    let end = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let start;

    if (today.getDate() === 1) {
      start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      end = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of previous month
    } else {
      start = new Date(today.getFullYear(), today.getMonth(), 1);
    }

    return {
      logic: "or",
      filters: [
        {
          logic: "and",
          filters: [
            {
              field: "createdOnUtc.date",
              operator: "lt",
              value: end,
            },
            {
              field: "createdOnUtc.date",
              operator: "gt",
              value: start,
            },
          ],
        },
      ],
    };
  }, []);

  const getFilters = useCallback(
    (filters) => {
      let fetchFilters = { ...filters };
      fetchFilters.advancedFilter ??= getAdvancedFilters();
      return fetchFilters;
    },
    [getAdvancedFilters]
  );

  const actionTemplate = useCallback(
    () => (
      <Export
        filters={getFilters(filters)}
        exportFunction={exportOrders}
        exportedEntity={"orders"}
        warningMessage={
          "If no filtration was applied, orders of this month will be exported."
        }
        isLoading={isLoadingExportedOrders}
      />
    ),
    [filters, exportOrders, isLoadingExportedOrders, getFilters]
  );

  return (
    <>
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={OrderColumns(
              handleApproveOrderClick,
              handleRejectOrderClick,
              handleReverseOrderClick
            )}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Orders Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={() => {
              navigate(`/baggage-management/stock/orders/add`);
            }}
            actionTemplate={actionTemplate}
          />
        </CardBody>
      </Card>
      <ApproveModal
        visible={approveModalVisibility}
        toggle={() => setApproveModalVisibility(false)}
        orderData={orderData}
      />
      <RejectModal
        visible={rejectModalVisibility}
        toggle={() => setRejectModalVisibility(false)}
        orderData={orderData}
      />
      <ReverseModal
        visible={reverseModalVisibility}
        toggle={() => setReverseModalVisibility(false)}
        orderData={orderData}
      />
    </>
  );
};

export default Orders;
