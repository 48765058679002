import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Col,
  Row,
  Alert,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import {
  useUpdateItemMutation,
  useSettleItemQuantityMutation,
} from "@src/redux/stock/item";
import { useGetQuantityTypeListQuery } from "@src/redux/stock/quantityType";
import {
  faTag,
  faBoxesPacking,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { SelectInput } from "@src/components/inputs";
import LoadingButton from "@src/components/shared/LoadingButton";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import { AlertCircle } from "react-feather";

const UpdateQuantityModal = ({
  visible,
  toggle,
  itemData,
  quantitySettlement = false,
}) => {
  const [updateStockItem, { isLoading }] = useUpdateItemMutation();
  const [settleItemQuantity] = useSettleItemQuantityMutation();
  const { data: quantityTypes } = useGetQuantityTypeListQuery();

  const [showAlert, setShowAlert] = useState(false);

  const itemsSchema = yup.object().shape({
    newQuantity: yup
      .number()
      .required("New Quantity is required")
      .test(
        "is-correct-sign",
        quantitySettlement
          ? "New Quantity must be less than 0"
          : "New Quantity must be greater than 0",
        (value) => (quantitySettlement ? value < 0 : value > 0)
      ),
    quantityTypeId: yup.number().required("Quantity type is required"),
    comments: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(itemsSchema),
    mode: "onSubmit",
    defaultValues: {
      newQuantity: "",
      quantityTypeId: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        newQuantity: "",
        quantityTypeId: "",
      });
      setShowAlert(false);
    }
  }, [visible, formMethods]);

  const getNewAvailablePieces = (formData) => {
    const selectedQuantityType = quantityTypes.find(
      (type) => type.id === formData.quantityTypeId
    );

    const isBoxes = selectedQuantityType?.name.toLowerCase() === "boxes";
    const quantity = isBoxes
      ? formData.newQuantity * itemData.piecesPerBox
      : formData.newQuantity;

    return itemData.availablePieces + quantity;
  };

  const handleFormSubmit = async (formData) => {
    if (getNewAvailablePieces(formData) < 0) {
      setShowAlert(true);
      return;
    }

    const mutation = quantitySettlement ? settleItemQuantity : updateStockItem;

    await mutation({
      stockItemId: itemData.id,
      newQuantity: formData.newQuantity,
      quantityTypeId: formData.quantityTypeId,
      comments: formData.comments,
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: quantitySettlement
            ? "Item Quantity adjusted successfully!"
            : "Item Quantity added successfully!",
        });
        toggle(false);
      });
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {quantitySettlement
              ? "Add Stock Item Quantity Adjustment"
              : "Add Stock Item Quantity"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <Row tag="dl" className="mb-0">
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Item Name:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {itemData?.name}
                  </Col>

                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Available Pieces:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {itemData?.availablePieces || ""}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row tag="dl" className="mb-0">
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Pieces Per Box:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {itemData?.piecesPerBox}
                  </Col>

                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Available In Boxes:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {itemData?.availableInBoxes || ""}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <FormGroup>
                  <SelectInput
                    twoLines={true}
                    label="Quantity Type"
                    name="quantityTypeId"
                    options={
                      quantityTypes?.map((type) => ({
                        value: type.id,
                        label: type.name,
                      })) || []
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <TextInput
                    twoLines={true}
                    label="New Quantity"
                    name="newQuantity"
                    icon={faBoxesPacking}
                  />
                </FormGroup>
              </Col>
              {quantitySettlement && (
                <Col lg="12" md="12" className="mb-1">
                  <TextInput
                    twoLines
                    label="Comments"
                    name="comments"
                    type="textarea"
                    rows="3"
                    icon={faMessage}
                  />
                </Col>
              )}
              {showAlert && (
                <Col md={12}>
                  <Alert color="danger">
                    <div className="alert-body">
                      <AlertCircle size={14} />
                      <span className="align-middle ms-50">
                        The new available pieces would be negative. Please
                        adjust the quantity.
                      </span>
                    </div>
                  </Alert>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default UpdateQuantityModal;
