import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useAddItemTypeMutation } from "@src/redux/found-item/item-type";
import { faTag, faMessage } from "@fortawesome/free-solid-svg-icons";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";

const ItemTypeModal = ({ visible, toggle }) => {
  const [addItemType] = useAddItemTypeMutation();

  const itemTypesSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(itemTypesSchema),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        name: "",
        description: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await addItemType({
      name: formData.name,
      description: formData.description,
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "New Found Item Type added successfully!",
        });
        toggle(false);
      });
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Add Found Item Type</ModalHeader>
          <ModalBody>
            <FormGroup>
              <TextInput
                twoLines={true}
                label="Name"
                name="name"
                icon={faTag}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                twoLines={true}
                label="Description"
                name="description"
                type="textarea"
                rows="5"
                icon={faMessage}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ItemTypeModal;
