import { mainApi } from "../mainApi";

export const logsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getLogs: builder.query({
      query: (filters) => ({
        url: `/stock/logs/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "logs", id: "LIST" }],
    }),
    exportLogs: builder.mutation({
      query: (filters) => ({
        url: `/stock/logs/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetLogsQuery, useExportLogsMutation } = logsApi;
