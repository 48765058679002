import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useRejectOrderMutation } from "@src/redux/stock/order";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import GeneralInfo from "./GeneralInfo";
import LoadingButton from "@src/components/shared/LoadingButton";

const RejectModal = ({ visible, toggle, orderData }) => {
  const [rejectOrder, { isLoading }] = useRejectOrderMutation();
  const rejectOrderSchema = yup.object().shape({
    rejectionReason: yup.string().required(),
  });

  const formMethods = useForm({
    resolver: yupResolver(rejectOrderSchema),
    mode: "onSubmit",
    defaultValues: {
      rejectionReason: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        rejectionReason: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await rejectOrder({
      id: orderData.id,
      rejectionReason: formData.rejectionReason,
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Order rejected successfully!",
        });
        toggle(false);
      });
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Reject Order</ModalHeader>
          <ModalBody>
            <GeneralInfo orderData={orderData} />
            <Row>
              <hr />
              <Col md={12}>
                <FormGroup>
                  <TextInput
                    twoLines={true}
                    label="Rejection Reason"
                    name="rejectionReason"
                    icon={faTag}
                    type="textarea"
                    rows="5"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default RejectModal;
