import LeftBehindList from ".";
import AddLeftBehindItem from "./leftBehindForm";
import Details from "./details";
import Forward from "./forward";

const LeftBehindListRoutes = [
  {
    path: "/left-behind/list",
    index: true,
    element: <LeftBehindList />,
  },
  {
    path: "/left-behind/list/add",
    index: true,
    element: <AddLeftBehindItem />,
  },
  {
    path: "/left-behind/list/:id",
    index: true,
    element: <Details />,
  },
  {
    path: "/left-behind/list/:id/forward",
    index: true,
    element: <Forward />,
  },
];

export default LeftBehindListRoutes;
