import React, { Fragment, useEffect, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from "reactstrap";
import { useAddOrderMutation } from "@src/redux/stock/order";
import { useGetStationListQuery } from "@src/redux/station/station";
import { useGetQuantityTypeListQuery } from "@src/redux/stock/quantityType";
import { useGetItemListQuery } from "@src/redux/stock/item";
import { useNavigate } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { TextInput, SelectInput } from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import { Plus, AlertCircle } from "react-feather";
import OrderItem from "./OrderItem";

// ** Schema for repeater items
const repeaterItemSchema = yup.object().shape({
  stockItemId: yup.string().required("Stock item is required"),
  quantityTypeId: yup.string().required("Quantity type is required"),
  quantity: yup.number().required("Quantity is required").positive().integer(),
});

// ** Schema for the whole form with custom validation to prevent duplicate items
const orderSchema = yup.object().shape({
  station: yup
    .string()
    .required()
    .test(
      "len",
      "Station must be 3 characters in length",
      (val) => val.length === 3
    ),
  comments: yup.string().nullable(),
  orderItems: yup
    .array()
    .of(repeaterItemSchema)
    .min(1, "At least one item is required")
    .test("no-duplicate-items", "Duplicate items are not allowed", (value) => {
      const stockItemIds = value.map((item) => item.stockItemId);
      return new Set(stockItemIds).size === stockItemIds.length;
    }),
});

const AddOrder = () => {
  const navigate = useNavigate();
  const [addOrderMutation] = useAddOrderMutation();
  const { data: stationList, isFetching } = useGetStationListQuery();
  const { data: quantityTypeList } = useGetQuantityTypeListQuery();
  const { data: itemlist } = useGetItemListQuery();

  const formMethods = useForm({
    resolver: yupResolver(orderSchema),
    mode: "onSubmit",
    defaultValues: {
      station: "",
      comments: "",
      orderItems: [{ stockItemId: "", quantityTypeId: "", quantity: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: "orderItems",
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    formMethods.reset({
      station: "",
      comments: "",
      orderItems: [{ stockItemId: "", quantityTypeId: "", quantity: "" }],
    });
  }, [formMethods]);

  const handleFormSubmit = async (formData) => {
    // Validate duplicates manually
    const stockItemIds = formData.orderItems.map((item) => item.stockItemId);
    const hasDuplicates = new Set(stockItemIds).size !== stockItemIds.length;

    if (hasDuplicates) {
      setError("orderItems", {
        type: "manual",
        message: "Duplicate items are not allowed",
      });
      return;
    }

    await addOrderMutation(formData)
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Order Added Successfully!",
        });
        navigate(`/baggage-management/stock/orders`);
      });
  };

  const addItem = () => {
    append({ stockItemId: "", quantityTypeId: "", quantity: "" });
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Add Order</CardTitle>
        </CardHeader>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <CardBody>
              <div className="info-container">
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <SelectInput
                      twoLines={true}
                      label="Station"
                      name="station"
                      options={
                        stationList?.map((type) => ({
                          value: type.id,
                          label: type.id,
                        })) || []
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" className="mb-1">
                    <hr />
                    <CardTitle
                      tag="h4"
                      className="mb-0 fw-bolder"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        Order Items
                        <p className="text-muted m-0 font-small-2">
                          Order must contain one item at least
                        </p>
                      </span>
                      <Button
                        outline
                        className="btn-icon"
                        color="warning"
                        onClick={addItem}
                      >
                        <Plus size={14} />
                        <span className="align-middle ms-25">Add Item</span>
                      </Button>
                    </CardTitle>
                    {fields.map((item, index) => (
                      <OrderItem
                        key={item.id}
                        item={item}
                        index={index}
                        remove={remove}
                        itemlist={itemlist}
                        quantityTypeList={quantityTypeList}
                      />
                    ))}
                    {errors.orderItems && (
                      <Alert color="danger">
                        <div className="alert-body">
                          <AlertCircle size={14} />
                          <span className="align-middle ms-50">
                            {errors.orderItems.message}
                          </span>
                        </div>
                      </Alert>
                    )}
                    <hr />
                  </Col>
                  <Col lg="12" md="12" className="mb-1">
                    <TextInput
                      twoLines={true}
                      label="Comments"
                      name="comments"
                      type="textarea"
                      rows="3"
                      icon={faMessage}
                    />
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Save
              </Button>
              <Button
                type="button"
                color="secondary"
                className="mx-1"
                onClick={() => {
                  navigate(`/stock/orders`);
                }}
              >
                Cancel
              </Button>
            </CardFooter>
          </Form>
        </FormProvider>
      </Card>
    </Fragment>
  );
};

export default memo(AddOrder);
