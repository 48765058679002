// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import { mainApi } from './mainApi';

const rootReducer = { 
    navbar,
     layout,
     [mainApi.reducerPath]: mainApi.reducer,
    };

    const middlewares =[
        mainApi.middleware
    ];

export const storeCfg =  {rootReducer, middlewares};
