import { mainApi } from "../mainApi";

export const orderTagTypeApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrderTagTypeList: builder.query({
      query: () => ({
        url: `/stock/order/orderTagType/list`,
        method: "GET",
      }),
    }),
    getOrderTagTypes: builder.query({
      query: (filters) => ({
        url: `/stock/order/orderTagType/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "orderTagTypes", id: "LIST" }],
    }),
    addOrderTagType: builder.mutation({
      query: (data) => ({
        url: `/stock/order/orderTagType/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "orderTagTypes", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrderTagTypeListQuery,
  useGetOrderTagTypesQuery,
  useAddOrderTagTypeMutation,
  useLazyGetOrderTagTypeListQuery,
} = orderTagTypeApi;
