import { Dropdown } from "primereact/dropdown";
import {
  faBoxesPacking,
  faPuzzlePiece,
  faBoxesStacked,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTruncatedDescription } from "@src/utility/Utils";

const QuantityTypeColumns = () => {
  const DescriptionBodyTemplate = ({ description }) => (
    <span>{getTruncatedDescription(description)}</span>
  );

  const types = ["pieces", "boxes", "other"];

  const getType = (type) => {
    switch (type) {
      case "pieces":
        return faPuzzlePiece;
      case "boxes":
        return faBoxesStacked;
      case "other":
        return faBoxesPacking;
    }
  };
  const quantityTypeBodyTemplate = (rowData) => {
    return (
      <span className="text-capitalize">
        <FontAwesomeIcon
          icon={getType(rowData.type)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {rowData.type}
      </span>
    );
  };

  const typeFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={types}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={typeItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const typeItemTemplate = (option) => {
    return (
      <span className="text-capitalize">
        <FontAwesomeIcon
          icon={getType(option)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {option}
      </span>
    );
  };
  return [
    {
      header: "Name",
      filter: true,
      field: "name",
      sortable: false,
    },
    {
      header: "Type",
      filter: true,
      field: "type",
      sortable: false,
      body: quantityTypeBodyTemplate,
      filterElement: typeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Description",
      filter: false,
      field: "description",
      sortable: false,
      body: DescriptionBodyTemplate,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
    },
  ];
};

export default QuantityTypeColumns;
