import React from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { useGetLeftBehindItemQuery } from "@src/redux/left-behind/list";
import Loader from "@src/components/shared/Loader";
import { ReadonlyInput } from "@src/components/inputs";
import FileDetailView from "@src/components/shared/FileDetailView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faForward,
  faPlane,
  faCalendar,
  faTags,
  faComment,
  faBoxes,
  faTag,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { useLazyDownloadFileQuery } from "@src/redux/general/file";
import { saveAs } from "file-saver";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";

const LeftBehindDetailsPage = () => {
  const { id } = useParams();
  const {
    data: leftBehindItem,
    isLoading,
    error,
  } = useGetLeftBehindItemQuery(id);
  const [downloadFile, { isLoading: loadingDownloadFile }] =
    useLazyDownloadFileQuery();

  if (isLoading) return <Loader />;
  if (error) return <div>Error fetching data</div>;

  const {
    flightNumber,
    flightDate,
    destination,
    faultStation,
    numberOfPieces,
    remainingPieces,
    reason,
    files,
    tags,
    comments,
  } = leftBehindItem;

  const handleDownload = async (identifier) => {
    var category = "LeftBehind";
    const response = await downloadFile(
      `identifier=${identifier}&category=${category}`
    ).unwrap();

    const blob = new Blob([response], { type: response.type });
    saveAs(blob, identifier);
    openSweetAlert({
      title: `File downloaded Successfully!`,
    });
  };

  return (
    <>
      <Row>
        <Col lg="12" md="12" xs="12">
          <Card>
            <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
              <CardTitle tag="h1" className="fw-bolder">
                Left-Behind Item Details
              </CardTitle>
              <div>
                <Button.Ripple
                  type="button"
                  color="dark"
                  href={`/baggage-management/left-behind/list/${id}/forward`}
                  tag="a"
                >
                  <FontAwesomeIcon icon={faForward} className="font-medium-3" />{" "}
                  &nbsp; Forward
                </Button.Ripple>
              </div>
            </CardHeader>
            <CardBody>
              <div className="info-container">
                <br />
                <Row>
                  <CardTitle tag="h5" className="mb-0">
                    <span className="section-label">Station details</span>
                    <hr style={{ width: "15%" }} />
                  </CardTitle>
                  <Col md={4}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Flight Number"
                        name="flightNumber"
                        icon={faPlane}
                        value={flightNumber}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Flight Date"
                        name="flightDateString"
                        icon={faCalendar}
                        value={flightDate}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Destination Station"
                        name="destination"
                        icon={faLocationDot}
                        value={destination}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Fault Station"
                        name="faultStation"
                        icon={faLocationDot}
                        value={faultStation}
                      />
                    </div>
                  </Col>
                  <CardTitle tag="h5" className="mb-0">
                    <span className="section-label">Item details</span>
                    <hr style={{ width: "15%" }} />
                  </CardTitle>
                  <Col md={4}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Number of Pieces"
                        name="numberOfPieces"
                        type="number"
                        icon={faBoxes}
                        value={numberOfPieces}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Remaining Pieces"
                        name="remainingPieces"
                        type="number"
                        icon={faBoxes}
                        value={remainingPieces}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Reason"
                        icon={faTag}
                        value={reason}
                      />
                    </div>
                  </Col>
                  <CardTitle tag="h5" className="mb-0">
                    <span className="section-label">More details</span>
                    <hr style={{ width: "15%" }} />
                  </CardTitle>
                  <Col md={12}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Tags"
                        name="tags"
                        icon={faTags}
                        value={tags}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-2">
                      <ReadonlyInput
                        twoLines
                        label="Comments"
                        name="comments"
                        type="textarea"
                        rows="5"
                        icon={faComment}
                        value={comments}
                      />
                    </div>
                  </Col>
                </Row>
                <CardTitle tag="h5" className="mt-4 mb-2">
                  Files
                </CardTitle>
                <FileDetailView files={files} onDownload={handleDownload} />{" "}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LeftBehindDetailsPage;
