import { mainApi } from "../mainApi";

export const stationApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getStationList: builder.query({
      query: () => ({
        url: `/station/list`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetStationListQuery } = stationApi;
