import React from "react";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import { FileText, Download, Image } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";

const FileDetailView = ({ files, onDownload = () => {} }) => {
  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return <Image size="28" />;
    } else {
      return <FileText size="28" />;
    }
  };

  const fileList = files.map((file, index) => (
    <ListGroupItem
      key={`${file.identifier}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center">
        <div className="file-preview me-1">{renderFilePreview(file)}</div>
        <div>
          <p className="file-name mb-0">{file.name}</p>
          <p className="file-size mb-0">{(file.size / 1024).toFixed(2)} KB</p>
        </div>
      </div>
      <Button
        id={`file-${index}`}
        color="primary"
        outline
        size="sm"
        className="btn-icon"
        onClick={() => onDownload(file.identifier)}
      >
        <Download size={14} /> Download
      </Button>
      <UncontrolledTooltip target={`file-${index}`} placement="top">
        Download
      </UncontrolledTooltip>
    </ListGroupItem>
  ));

  return (
    <div className="mb-2">
      {files.length ? (
        <ListGroup className="my-2">{fileList}</ListGroup>
      ) : (
        <p>No files available for download.</p>
      )}
    </div>
  );
};

export default FileDetailView;
