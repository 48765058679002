import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useApproveOrderMutation } from "@src/redux/stock/order";
import { useGetOrderTagTypeListQuery } from "@src/redux/stock/tagType";
import { faTag, faCalendar, faPlane } from "@fortawesome/free-solid-svg-icons";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import { formatDateTime } from "@src/utility/Utils";
import CalendarInput from "@src/components/inputs/CalendarInput";
import { formatDateToString } from "@src/utility/Utils";
import { SelectInput } from "@src/components/inputs";

const ApproveModal = ({ visible, toggle, orderData }) => {
  const [approveOrder] = useApproveOrderMutation();
  const { data: tagTypes, error, isLoading } = useGetOrderTagTypeListQuery();
  const approveOrderSchema = yup.object().shape({
    flightDate: yup
      .date()
      .required()
      .min(
        new Date(new Date().toISOString()),
        "Flight date must be in the future (UTC)"
      ),
    flightNumber: yup
      .number()
      .typeError("Flight No must be a number")
      .required()
      .moreThan(0, "Flight No can not be negative")
      .max(9999, "Flight No cannot exceed 4 digits")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    tag: yup.string().nullable(),
    orderTagTypeId: yup.number().required("Tag type is required"),
  });

  const formMethods = useForm({
    resolver: yupResolver(approveOrderSchema),
    mode: "onSubmit",
    defaultValues: {
      flightDate: "",
      flightNumber: "",
      tag: "",
      orderTagTypeId: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        flightDate: "",
        flightNumber: "",
        tag: "",
        orderTagTypeId: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await approveOrder({
      id: orderData.id,
      date: formatDateToString(formData.flightDate),
      flightNumber: formData.flightNumber + "",
      tag: formData.tag,
      orderTagTypeId: formData.orderTagTypeId,
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Order approved successfully!",
        });
        toggle(false);
      });
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Approve Order</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                {" "}
                <Row>
                  <Col tag="dt" sm="4" className="fw-bolder mb-1">
                    Number:
                  </Col>
                  <Col tag="dd" sm="8" className="mb-1">
                    {orderData?.id}
                  </Col>
                  <Col tag="dt" sm="4" className="fw-bolder mb-1">
                    Station:
                  </Col>
                  <Col tag="dd" sm="8" className="mb-1">
                    {orderData?.station}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {" "}
                <Row>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Total Weight:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {orderData?.totalWeight}
                  </Col>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Created On (Utc):
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {orderData?.createdOnUtc &&
                      formatDateTime(new Date(orderData?.createdOnUtc))}
                  </Col>
                </Row>
              </Col>
              <hr />
              <Col md={6}>
                <div className="mb-1">
                  <TextInput
                    twoLines={true}
                    label="Flight Number"
                    name="flightNumber"
                    icon={faPlane}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1">
                  <CalendarInput
                    twoLines={true}
                    label="Flight Date"
                    name="flightDate"
                    icon={faCalendar}
                    options={{
                      enableTime: true,
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <SelectInput
                    twoLines={true}
                    label="Tag Type"
                    name="orderTagTypeId"
                    icon={faCalendar}
                    options={
                      tagTypes?.map((type) => ({
                        value: type.id,
                        label: type.name,
                      })) || []
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <TextInput
                    twoLines={true}
                    label="Tag"
                    name="tag"
                    icon={faTag}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ApproveModal;
