import QuantityType from ".";

const QuantityTypeRoutes = [
  {
    path: "/stock/quantity-type",
    index: true,
    element: <QuantityType />,
  },
];

export default QuantityTypeRoutes;
