import { useFormContext, Controller } from "react-hook-form";
import { Label, FormFeedback, Col, Row } from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

function SelectInput({
  label,
  defaultValue,
  name,
  options = [],
  optionKey = "value",
  optionLabel = "label",
  changeCallback,
  twoLines = false,
  ...props
}) {
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    watch,
  } = useFormContext();
  const value = watch(name);
  const { t } = useTranslation();
  const selectedObj =
    (options &&
      options?.find((a) =>
        optionKey ? a[optionKey] === value : a === value
      )) ||
    "";

  const handleGetLabel = (o) => o[optionLabel];

  return (
    <Row>
      <Col md={twoLines ? "12" : "4"} sm="12">
        <Label className="form-label" for={name}>
          <h6>{label}</h6>
        </Label>
      </Col>
      <Col md={twoLines ? "12" : "8"} sm="12">
        <Controller
          id={name}
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            watch(name);
            return (
              <>
                <Select
                  className={classnames("react-select", {
                    "is-invalid": error && true,
                  })}
                  placeholder={t("search")}
                  key={optionKey}
                  classNamePrefix="select"
                  options={options}
                  defaultInputValue={defaultValue}
                  isClearable
                  menuPlacement="auto"
                  isOptionDisabled={(option) => option.disabled}
                  getOptionLabel={handleGetLabel}
                  value={selectedObj}
                  getOptionValue={(option) =>
                    option[optionKey] != undefined ? option[optionKey] : option
                  }
                  onBlur={() => trigger(name)}
                  onChange={(value) => {
                    setValue(name, value ? value[optionKey] : "");
                    if (changeCallback) {
                      changeCallback(value);
                    }
                  }}
                  {...props}
                />
                {error && <FormFeedback>{error.message}</FormFeedback>}
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
}

export default SelectInput;
