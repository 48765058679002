// ** Router imports
import { lazy } from "react";
import * as reactDom from "react-router-dom";
import config from "@src/configs/themeConfig";
// ** GetRoutes
// import { getRoutes } from "./routes";
import { getRoutes } from "@app/layout";
// ** Hooks Imports
import { useLayout, layoutConfig, useUser } from "@app/layout";
import navigation from "../navigation/horizontal";
const { Navigate } = reactDom;
const Home = lazy(() => import("../views/Home"));

// ** Merge Routes
import LeftBehindReasonsRoutes from "@src/views/left-behind/reason/route";
import StockItemsRoutes from "@src/views/stock/item/route";
import QuantityTypeRoutes from "@src/views/stock/quantity-type/route";
import OrdersRoutes from "@src/views/stock/order/route";
import LogsRoutes from "@src/views/stock/log/route";
import OrderTagRoutes from "@src/views/stock/order-tag/route";
import SettingRoutes from "@src/views/setting/route";
import ItemTypesRoutes from "@src/views/found-item/item-type/route";
import LeftBehindListRoutes from "@src/views/left-behind/list/route";

const baseRoute = config.app.baseRoute;

const mainRoutes = [
  {
    path: `${baseRoute}/home`,
    index: true,
    element: <Home />,
  },
  ...LeftBehindReasonsRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...StockItemsRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...QuantityTypeRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...OrdersRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...LogsRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...OrderTagRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...SettingRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...ItemTypesRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...LeftBehindListRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  {
    path: "*",
    element: <Navigate to={`${baseRoute}/home`} />,
  },
];

const Router = () => {
  const { useRoutes } = reactDom;
  const { layout } = useLayout({ ...layoutConfig });

  const allRoutes = getRoutes("horizontal", mainRoutes, reactDom, {
    navigations: [...navigation],
  });
  // const routes = reactDom.useRoutes(mainRoutes);
  const routes = useRoutes([...allRoutes]);

  return routes;
};

export default Router;
