import { mainApi } from "../mainApi";

export const orderApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (filters) => ({
        url: `/stock/order/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "orders", id: "LIST" }],
    }),
    addOrder: builder.mutation({
      query: (data) => ({
        url: `/stock/order/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "orders", id: "LIST" }],
    }),
    getOrdert: builder.query({
      query: (id) => ({
        url: `/stock/order/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "orders", id: "Details" }],
    }),
    approveOrder: builder.mutation({
      query: (data) => ({
        url: `/stock/order/${data.id}/approve`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "orders", id: "LIST" },
        { type: "orders", id: "Details" },
      ],
    }),
    rejectOrder: builder.mutation({
      query: (data) => ({
        url: `/stock/order/${data.id}/reject`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "orders", id: "LIST" },
        { type: "orders", id: "Details" },
      ],
    }),
    reverseOrder: builder.mutation({
      query: (data) => ({
        url: `/stock/order/${data.id}/reverse`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "orders", id: "LIST" },
        { type: "orders", id: "Details" },
      ],
    }),
    exportOrders: builder.mutation({
      query: (filters) => ({
        url: `/stock/order/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
    updateOrder: builder.mutation({
      query: (data) => ({
        url: `/stock/order/${data.id}/update`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        { type: "orders", id: "LIST" },
        { type: "orders", id: "Details" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrdersQuery,
  useAddOrderMutation,
  useGetOrdertQuery,
  useApproveOrderMutation,
  useRejectOrderMutation,
  useReverseOrderMutation,
  useExportOrdersMutation,
  useUpdateOrderMutation,
} = orderApi;
