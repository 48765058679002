import { mainApi } from "../mainApi";

export const passengersApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    searchFlightPassengers: builder.mutation({
      query: (data) => ({
        url: `/founditems/passengers`,
        method: "POST",
        body: data,
      }),
      providesTags: (result) => [{ type: "flightPassengers", id: "List" }],
      invalidatesTags: [{ type: "flightPassengers", id: "List" }],
    }),
  }),
  overrideExisting: false,
});

export const { useSearchFlightPassengersMutation } = passengersApi;
