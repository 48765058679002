import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetItemlistQuery,
  useExportFoundItemsMutation,
} from "@src/redux/found-item/list";
import itemColumns from "./columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { Column } from "primereact/column";
import { useGetItemTypeListQuery } from "@src/redux/found-item/item-type";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import Export from "../../common/Export";

const FoundItemsList = () => {
  const { data: itemTypeList } = useGetItemTypeListQuery();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    "itemType.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    flightNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "foundItem.flightDate": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    "foundItem.receivedDate": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    fromSector: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    toSector: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "foundItem.status": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    createdBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "foundItem.createdOnUTC.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetItemlistQuery(filters);
  const [exportFoundItems, { isLoading: isLoadingExportedFoundItems }] =
    useExportFoundItemsMutation();

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };
  const itemTypeItemTemplate = (option) => {
    return <span className="text-capitalize">{option}</span>;
  };
  const itemTypeFilterTemplate = (options, itemTypes) => {
    return (
      <Dropdown
        value={options.value}
        options={itemTypes?.map((item) => item.name)}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={itemTypeItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const actionTemplate = () => (
    <Export
      filters={filters}
      exportFunction={exportFoundItems}
      exportedEntity={"FoundItems"}
      isLoading={isLoadingExportedFoundItems}
    />
  );

  return (
    <>
      <BreadCrumbs pageName="foundItemList" pageTitle="Found-Item List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            actionTemplate={actionTemplate}
            columns={itemColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Found Items Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            tableState={filters}
            displayAdd={true}
            handleAdd={() => {
              navigate(`/baggage-management/found-items/list/add`);
            }}
          >
            <Column
              field="itemType"
              filter={true}
              header="itemType"
              filterField="itemType.name"
              showFilterMatchModes={false}
              filterElement={(options) =>
                itemTypeFilterTemplate(options, itemTypeList)
              }
              bodyStyle={{
                width: "10%",
              }}
            />
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default FoundItemsList;
