import React, { Fragment, useState, useEffect, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Table,
} from "reactstrap";
import { useAddFoundItemMutation } from "@src/redux/found-item/list";
import { useNavigate } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import { TextInput, SelectInput } from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import { Plus, AlertCircle, Edit, X } from "react-feather";
import LoadingButton from "@src/components/shared/LoadingButton";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import AddFoundItemModal from "./AddFoundItemModal";
import CalendarInput from "@src/components/inputs/CalendarInput";
import {
  faCalendar,
  faPlane,
  faHashtag,
  faBuilding,
  faBookmark,
  faAddressCard,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import { useGetStationListQuery } from "@src/redux/station/station";
import { format } from "date-fns";
import { useGetItemTypeListQuery } from "@src/redux/found-item/item-type";

const itemsFoundSchema = yup.object().shape({
  itemTypeId: yup.number().required("Item type is required").integer(),
  description: yup.string().nullable(),
  kind: yup
    .string()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    }),
  amount: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .typeError("Amount must be a number")
    .positive("Amount must be a positive number")
    .test("is-decimal", "Amount must be a float", (value) => {
      return value === null || Number(value) === value;
    }),
});

const foundItemSchema = yup.object().shape({
  flightNumber: yup
    .string()
    .matches(
      /^[1-9]\d{0,3}$/,
      "Flight number must be a number between 1 and 9999"
    )
    .required("Flight number is required"),
  flightDate: yup
    .date()
    .max(new Date(), "Flight date cannot be in the future.")
    .required("Flight date is required"),
  receivedDate: yup
    .date()
    .max(new Date(), "Received date cannot be in the future.")
    .required("Received date is required"),
  seatNumber: yup.string().nullable(),
  fromSector: yup.string().required("From sector is required"),
  toSector: yup.string().required("To sector is required"),
  bookingReference: yup.string().nullable(),
  passengerName: yup.string().nullable(),
  contactInfo: yup.string().nullable(),
  comments: yup.string().nullable(),
  itemsFound: yup
    .array()
    .of(itemsFoundSchema)
    .min(1, "At least one item must be added"),
});

const AddFoundItem = () => {
  const navigate = useNavigate();
  const [addFoundItemMutation, { isLoading }] = useAddFoundItemMutation();
  const { data: stationList, isFetching } = useGetStationListQuery();
  const { data: itemTypeList } = useGetItemTypeListQuery();

  const formMethods = useForm({
    resolver: yupResolver(foundItemSchema),
    mode: "onSubmit",
    defaultValues: {
      flightNumber: "",
      flightDate: "",
      receivedDate: "",
      seatNumber: "",
      fromSector: "",
      toSector: "",
      bookingReference: "",
      passengerName: "",
      contactInfo: "",
      comments: "",
      itemsFound: [],
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    formMethods.reset({
      flightNumber: "",
      flightDate: "",
      receivedDate: "",
      seatNumber: "",
      fromSector: "",
      toSector: "",
      bookingReference: "",
      passengerName: "",
      contactInfo: "",
      comments: "",
      itemsFound: [],
    });
  }, [formMethods]);

  const handleFormSubmit = async (formData) => {
    await foundItemSchema.validate(formData, { abortEarly: false });

    formData.flightDate = format(new Date(formData.flightDate), "yyyy-MM-dd");
    formData.receivedDate = format(
      new Date(formData.receivedDate),
      "yyyy-MM-dd"
    );

    await addFoundItemMutation(formData)
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Found Item Added Successfully!",
        });
        navigate(`/baggage-management/found-items/list`);
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setCurrentItem(null);
    setIsEditing(false);
  };

  const handleModalSave = (newItem) => {
    const itemsFound = formMethods.getValues("itemsFound");
    if (isEditing) {
      const updatedItems = itemsFound.map((item, idx) =>
        idx === currentItem.index ? newItem : item
      );
      formMethods.setValue("itemsFound", updatedItems);
    } else {
      formMethods.setValue("itemsFound", [...itemsFound, newItem]);
    }
    toggleModal();
  };

  const handleEditItem = (item, index) => {
    toggleModal();
    setIsEditing(true);
    setCurrentItem({ ...item, index });
  };

  const handleRemoveItem = (index) => {
    const itemsFound = formMethods.getValues("itemsFound");
    const updatedItems = itemsFound.filter((_, idx) => idx !== index);
    formMethods.setValue("itemsFound", updatedItems);
  };

  const getItemTypeName = (itemTypeId) => {
    const itemType = itemTypeList?.find((type) => type.id == itemTypeId);
    return itemType ? itemType.name : "Unknown Item Type";
  };

  const itemsFound = formMethods.watch("itemsFound");

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <BreadCrumbs pageName="addFoundItem" pageTitle="Add Found Item" />
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Add Found Item</CardTitle>
        </CardHeader>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <CardBody>
              <div className="info-container">
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Flight Number"
                      name="flightNumber"
                      icon={faPlane}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <SelectInput
                      label="From Sector"
                      name="fromSector"
                      icon={faBuilding}
                      options={
                        stationList?.map((type) => ({
                          value: type.id,
                          label: type.id,
                        })) || []
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <CalendarInput
                      label="Flight Date"
                      name="flightDate"
                      icon={faCalendar}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <SelectInput
                      label="To Sector"
                      name="toSector"
                      icon={faBuilding}
                      options={
                        stationList?.map((type) => ({
                          value: type.id,
                          label: type.id,
                        })) || []
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <CalendarInput
                      label="Received Date"
                      name="receivedDate"
                      icon={faCalendar}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Passenger Name"
                      name="passengerName"
                      icon={faAddressCard}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Seat Number"
                      name="seatNumber"
                      icon={faHashtag}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Contact Info"
                      name="contactInfo"
                      icon={faMobileScreen}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Booking Reference"
                      name="bookingReference"
                      icon={faBookmark}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" className="mb-1">
                    <hr />
                    <CardTitle
                      tag="h4"
                      className="mb-0 fw-bolder"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        Items Found
                        <p className="text-muted m-0 font-small-2">
                          You must add at least one found item
                        </p>
                      </span>
                      <Button
                        outline
                        className="btn-icon"
                        color="warning"
                        onClick={toggleModal}
                      >
                        <Plus size={14} />
                        <span className="align-middle ms-25">Add Item</span>
                      </Button>
                    </CardTitle>

                    {itemsFound.length > 0 ? (
                      <Table responsive bordered className="mt-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Item Type</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Kind</th>
                            <th>Amount</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemsFound.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{getItemTypeName(item.itemTypeId)}</td>
                              <td>
                                {item.description ? item.description : "-"}
                              </td>
                              <td>{item.kind ? item.kind : "-"}</td>
                              <td>
                                {item.amount && item.amount !== 0
                                  ? item.amount
                                  : "-"}
                              </td>
                              <td>
                                <Button
                                  outline
                                  color="primary"
                                  className="me-1"
                                  onClick={() => handleEditItem(item, index)}
                                >
                                  <Edit size={14} />
                                </Button>
                                <Button
                                  outline
                                  color="danger"
                                  onClick={() => handleRemoveItem(index)}
                                >
                                  <X size={14} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <Alert color="dark">
                        <div className="alert-body mt-1">
                          <AlertCircle size={14} />
                          <span className="align-middle ms-50">
                            No items found yet, please add at least one item.
                          </span>
                        </div>
                      </Alert>
                    )}

                    {errors.itemsFound && (
                      <Alert color="danger">
                        <div className="alert-body">
                          <AlertCircle size={14} />
                          <span className="align-middle ms-50">
                            {errors.itemsFound.message}
                          </span>
                        </div>
                      </Alert>
                    )}
                    <hr />
                  </Col>
                  <Col lg="12" md="12" className="mb-1">
                    <TextInput
                      twoLines
                      label="Comments"
                      name="comments"
                      type="textarea"
                      rows="3"
                    />
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <LoadingButton
                loading={isLoading}
                type="submit"
                color="secondary"
              >
                Save
              </LoadingButton>
              <Button
                outline
                type="button"
                color="dark"
                className="mx-1"
                onClick={() => {
                  navigate(`/lost-and-found/found-items`);
                }}
              >
                Cancel
              </Button>
            </CardFooter>
          </Form>
        </FormProvider>
      </Card>

      <AddFoundItemModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        onSave={handleModalSave}
        item={currentItem}
        isEditing={isEditing}
        itemTypeList={itemTypeList}
      />
    </Fragment>
  );
};

export default memo(AddFoundItem);
