import { mainApi } from "../mainApi";

export const leftbehindListApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeftBehindList: builder.query({
      query: (filters) => ({
        url: `/leftbehind/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "leftBehindList", id: "LIST" }],
    }),
    addLeftBehindItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/leftbehind`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "leftBehindList", id: "LIST" }],
    }),
    exportLeftBehindItems: builder.mutation({
      query: (filters) => ({
        url: `/leftbehind/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
    getLeftBehindItem: builder.query({
      query: (id) => ({
        url: `/leftbehind/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "leftBehindList", id: "Details" }],
    }),
    forwardLeftBehindItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/leftbehind/${formData.leftBehindId}/forward`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "leftBehindList", id: "LIST" },
        { type: "leftBehindList", id: "Details" },
      ],
    }),
    updateLeftBehindItem: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `/leftbehind/${id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "leftBehindList", id: "LIST" },
        { type: "leftBehindList", id: "Details" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLeftBehindListQuery,
  useAddLeftBehindItemMutation,
  useExportLeftBehindItemsMutation,
  useGetLeftBehindItemQuery,
  useForwardLeftBehindItemMutation,
  useUpdateLeftBehindItemMutation,
} = leftbehindListApi;
