import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useDeliverFoundItemMutation } from "@src/redux/found-item/list";
import { faTag, faCalendar } from "@fortawesome/free-solid-svg-icons";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import LoadingButton from "@src/components/shared/LoadingButton";
import CalendarInput from "@src/components/inputs/CalendarInput";
import { format } from "date-fns";

const DeliverModal = ({ visible, toggle, item }) => {
  const [deliverItem, { isLoading }] = useDeliverFoundItemMutation();
  const deliverItemSchema = yup.object().shape({
    recipientName: yup.string().required(),
    idNumber: yup.string().required(),
    deliveryDate: yup
      .date()
      .required()
      .max(
        new Date(new Date().setHours(0, 0, 0, 0)),
        "Delivery date cannot be in the future (UTC)"
      ),
  });

  const formMethods = useForm({
    resolver: yupResolver(deliverItemSchema),
    mode: "onSubmit",
    defaultValues: {
      recipientName: "",
      idNumber: "",
      deliveryDate: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        recipientName: "",
        idNumber: "",
        deliveryDate: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await deliverItem({
      id: item.id,
      recipientName: formData.recipientName,
      idNumber: formData.idNumber,
      deliveryDate: format(new Date(formData.deliveryDate), "yyyy-MM-dd"),
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Found Item Delivered successfully!",
        });
        toggle(false);
      });
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Deliver Found Item</ModalHeader>
          <ModalBody>
            <Row>
              <hr />
              <Col md={12}>
                <div className="mb-1">
                  <TextInput
                    twoLines={true}
                    label="Recipient Name"
                    name="recipientName"
                    icon={faTag}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-1">
                  <TextInput
                    twoLines={true}
                    label="Id Number"
                    name="idNumber"
                    icon={faTag}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-1">
                  <CalendarInput
                    twoLines={true}
                    label="Delivery Date"
                    name="deliveryDate"
                    icon={faCalendar}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default DeliverModal;
