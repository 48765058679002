import Reasons from ".";

const ReasonsRoutes = [
  {
    path: "/left-behind/reasons",
    index: true,
    element: <Reasons />,
  },
];

export default ReasonsRoutes;
