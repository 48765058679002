import { Label, FormFeedback, InputGroup, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext, Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import "@styles/react/libs/flatpickr/flatpickr.scss";

const CalendarInput = ({
  label,
  name,
  color = "primary",
  icon,
  twoLines = false,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Row>
      <Col md={twoLines ? "12" : "4"} sm="12">
        <Label className="form-label" for={name}>
          <h6>{label}</h6>
        </Label>
      </Col>
      <Col md={twoLines ? "12" : "8"} sm="12">
        <Controller
          id={name}
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <InputGroup>
                  {icon && (
                    <span className={`p-inputgroup-addon text-${color}`}>
                      <FontAwesomeIcon icon={icon} />
                    </span>
                  )}
                  <Flatpickr
                    id={field.name}
                    name={name}
                    value={field.value}
                    placeholder={label}
                    options={props.options}
                    onChange={(e) => field.onChange(e)}
                    className={`form-control ${error ? "is-invalid" : ""}`}
                  />
                  {error && <FormFeedback>{error?.message}</FormFeedback>}
                </InputGroup>
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default CalendarInput;
