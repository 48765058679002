import { useFormContext, Controller } from "react-hook-form";
import { Label, FormFeedback, Col, Row } from "reactstrap";
import Select from "react-select";
import classnames from "classnames";

function MultiSelectInput({
  label,
  defaultValue,
  name,
  options = [],
  optionKey = "value",
  optionLabel = "label",
  changeCallback,
  ...props
}) {
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    watch,
  } = useFormContext();
  const value = watch(name);

  const selectedObjs = options?.filter((a) =>
    optionKey ? value?.includes(a[optionKey]) : value?.includes(a)
  );

  const handleGetLabel = (o) => o[optionLabel];
  const customStyles = {
    multiValueLabel: (styles) => ({
      ...styles,
      color: "black !important",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "black",
    }),
  };
  return (
    <Row>
      <Col>
        <Label className="form-label" for={name}>
          <h6>{label}</h6>
        </Label>
      </Col>
      <Col md="8" sm="12">
        <Controller
          id={name}
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            watch(name);
            return (
              <>
                <Select
                  styles={customStyles}
                  className={classnames("react-select", {
                    "is-invalid": error && true,
                  })}
                  isMulti
                  key={optionKey}
                  value={selectedObjs || []}
                  classNamePrefix="select"
                  options={options}
                  defaultInputValue={defaultValue}
                  isClearable
                  menuPlacement="auto"
                  isOptionDisabled={(option) => option.disabled}
                  getOptionLabel={handleGetLabel}
                  getOptionValue={(option) =>
                    option[optionKey] != undefined ? option[optionKey] : option
                  }
                  onBlur={() => trigger(name)}
                  onChange={(value) => {
                    let values = value.map((val) => val[optionKey]);
                    setValue(name, values);
                    if (changeCallback) {
                      changeCallback(values);
                    }
                  }}
                  {...props}
                />
                {error && <FormFeedback>{error.message}</FormFeedback>}
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
}

export default MultiSelectInput;
