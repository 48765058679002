import { mainApi } from "../mainApi";

export const itemListApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getItemlist: builder.query({
      query: (filters) => ({
        url: `/founditems/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "foundItemList", id: "LIST" }],
    }),
    addFoundItem: builder.mutation({
      query: (data) => ({
        url: `/founditems/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "foundItemList", id: "LIST" }],
    }),
    getFoundItem: builder.query({
      query: (id) => ({
        url: `/founditems/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "foundItemList", id: "Details" }],
    }),
    updateFoundItem: builder.mutation({
      query: (data) => ({
        url: `/founditems/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        { type: "foundItemList", id: "LIST" },
        { type: "foundItemList", id: "Details" },
      ],
    }),
    deliverFoundItem: builder.mutation({
      query: (data) => ({
        url: `/founditems/${data.id}/deliver`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        { type: "foundItemList", id: "LIST" },
        { type: "foundItemList", id: "Details" },
      ],
    }),
    forwardFoundItem: builder.mutation({
      query: (data) => ({
        url: `/founditems/${data.id}/forward`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        { type: "foundItemList", id: "LIST" },
        { type: "foundItemList", id: "Details" },
      ],
    }),
    destroyFoundItem: builder.mutation({
      query: (data) => ({
        url: `/founditems/${data.id}/destroy`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        { type: "foundItemList", id: "LIST" },
        { type: "foundItemList", id: "Details" },
      ],
    }),
    exportFoundItems: builder.mutation({
      query: (filters) => ({
        url: `/founditems/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetItemlistQuery,
  useAddFoundItemMutation,
  useGetFoundItemQuery,
  useUpdateFoundItemMutation,
  useDeliverFoundItemMutation,
  useForwardFoundItemMutation,
  useDestroyFoundItemMutation,
  useExportFoundItemsMutation,
} = itemListApi;
