import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetLeftBehindListQuery,
  useExportLeftBehindItemsMutation,
} from "@src/redux/left-behind/list";
import leftBehindColumns from "./columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useNavigate } from "react-router-dom";
import Export from "../../common/Export";

const LeftBehindList = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    flightNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "flightDate.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    destination: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    faultStation: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    numberOfPieces: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    reason: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    createdBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetLeftBehindListQuery(filters);
  const [
    exportLeftBehindItems,
    { isLoading: isLoadingExportedLeftBehindItems },
  ] = useExportLeftBehindItemsMutation();
  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => (
    <Export
      filters={filters}
      exportFunction={exportLeftBehindItems}
      exportedEntity={"LeftBehindItems"}
      isLoading={isLoadingExportedLeftBehindItems}
    />
  );

  return (
    <>
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={leftBehindColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Left-Behind item Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            displayAdd={true}
            handleAdd={() => {
              navigate(`/baggage-management/left-behind/list/add`);
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default LeftBehindList;
