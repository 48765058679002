// ** Redux Imports
import {storeCfg} from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import {rtkQueryErrorHandler} from "./rtkQueryErrorHandler"

const store = configureStore({
  reducer: storeCfg.rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat([...storeCfg.middlewares, rtkQueryErrorHandler]);
  },
});

export { store };
