import { getTruncatedDescription } from "@src/utility/Utils";

const itemTypeColumns = () => {
  const DescriptionBodyTemplate = ({ description }) => (
    <span>{getTruncatedDescription(description)}</span>
  );

  return [
    {
      header: "Name",
      filter: true,
      field: "name",
      sortable: false,
    },
    {
      header: "Description",
      filter: false,
      field: "description",
      sortable: false,
      body: DescriptionBodyTemplate,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
    },
  ];
};

export default itemTypeColumns;
