import React from "react";
import { Form, Row, Col, Button } from "reactstrap";
import { X } from "react-feather";
import { SelectInput, TextInput, ReadonlyInput } from "@src/components/inputs";

const OrderItem = React.memo(
  ({ item, index, remove, itemlist, quantityTypeList }) => {
    return (
      <Form key={item.id}>
        <Row className="mb-2 pt-1 pb-2 justify-content-between rounded border align-items-center">
          <Col md={2} className="mb-md-0 mb-1">
            <h4 className="mb-1 text-warning fw-bolder border-bottom">
              Item - {index + 1}
            </h4>
          </Col>
          <Col md={12}></Col>
          <Col md={4} className="mb-md-0 mb-1">
            {!!item.orderItemId ? (
              <ReadonlyInput
                twoLines
                label="Stock Item"
                name={`orderItems[${index}].stockItemName`}
                defaultValue={item.stockItemName}
              />
            ) : (
              <SelectInput
                twoLines
                label="Stock Item"
                name={`orderItems[${index}].stockItemId`}
                options={
                  itemlist?.map((type) => ({
                    value: type.id,
                    label: type.name,
                  })) || []
                }
              />
            )}
          </Col>
          <Col md={2} className="mb-md-0 mb-1">
            <SelectInput
              twoLines
              label="Quantity Type"
              name={`orderItems[${index}].quantityTypeId`}
              options={
                quantityTypeList?.map((type) => ({
                  value: type.id,
                  label: type.name,
                })) || []
              }
            />
          </Col>
          <Col md={2} className="mb-md-0 mb-1">
            <TextInput
              twoLines
              label="Quantity"
              name={`orderItems[${index}].quantity`}
            />
          </Col>
          <Col md={2}>
            <Button
              color="danger"
              className="text-nowrap px-1"
              onClick={() => remove(index)}
              outline
            >
              <X size={14} className="me-50" />
              <span>Remove Item</span>
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default OrderItem;
