import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import { useGetLogsQuery, useExportLogsMutation } from "@src/redux/stock/log";
import LogsColumns from "./columns";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Export from "../../common/Export";

const Logs = () => {
  const { itemId } = useParams();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    orderId: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    operationType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    station: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    stockItemName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    quantityTypeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    quantity: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    createdOnUtc: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    createdBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const getItemFilters = (itemId) => {
    var advancedFilters = {
      logic: "or",
      filters: [
        {
          logic: "and",
          filters: [
            {
              field: "stockItemId",
              operator: "eq",
              value: itemId,
            },
          ],
        },
      ],
    };
    let fetchFilters = { ...filters };
    fetchFilters.advancedFilter = advancedFilters;
    return fetchFilters;
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetLogsQuery(
    itemId ? getItemFilters(itemId) : filters
  );
  const [exportLogs, { isLoading: isLoadingExportedLogs }] =
    useExportLogsMutation();

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => (
    <Export
      filters={filters}
      exportFunction={exportLogs}
      exportedEntity={"logs"}
      isLoading={isLoadingExportedLogs}
    />
  );

  return (
    <>
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={LogsColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Logs Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Logs;
