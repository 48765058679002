import { Calendar } from "primereact/calendar";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { formatDateTime } from "@src/utility/Utils";

const operationTypes = ["newSupplies", "newOrder", "reversedOrder"];

const getOperationTypesText = (type) => {
  switch (type) {
    case "newSupplies":
      return "New Supplies";
    case "newOrder":
      return "New Order";
    case "reversedOrder":
      return "Reversed Order";
    case "quantitySettlement":
      return "Quantity Settlement";
  }
};
const getOperationTypeSeverity = (type) => {
  switch (type) {
    case "newSupplies":
      return "info";
    case "newOrder":
      return "success";
    case "reversedOrder":
      return "warning";
    default:
      return "danger"; //QuantitySettlement
  }
};

const LogsColumns = () => {
  const createdOnUtcBodyTemplate = (rowData) => {
    return formatDateTime(new Date(rowData.createdOnUtc));
  };

  const createdOnUtcFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const operationTypeBodyTemplate = (rowData) => {
    return (
      <Tag
        value={getOperationTypesText(rowData.operationType)}
        severity={getOperationTypeSeverity(rowData.operationType)}
        className="text-capitalize"
      />
    );
  };

  const operationTypeItemTemplate = (option) => {
    return (
      <span className="text-capitalize">
        <Tag
          value={getOperationTypesText(option)}
          severity={getOperationTypeSeverity(option)}
        />
      </span>
    );
  };

  const operationTypeFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={operationTypes}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={operationTypeItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  return [
    {
      header: "Number",
      filter: true,
      field: "orderId",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { width: "10%" },
    },
    {
      header: "Operation Type",
      filter: true,
      field: "operationType",
      sortable: false,
      body: operationTypeBodyTemplate,
      filterElement: operationTypeFilterTemplate,
      showFilterMatchModes: false,
      bodyStyle: { width: "15%", textAlign: "center", overflow: "visible" },
    },
    {
      header: "Station",
      filter: true,
      field: "station",
      sortable: false,
      bodyStyle: { width: "10%" },
    },
    {
      header: "Stock Item",
      filter: true,
      field: "stockItemName",
      sortable: false,
      bodyStyle: { width: "10%" },
    },
    {
      header: "Quantity Type",
      filter: true,
      field: "quantityTypeName",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { textAlign: "center", overflow: "visible" },
      bodyStyle: { width: "10%" },
    },
    {
      header: "Quantity",
      filter: true,
      field: "quantity",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { width: "10%" },
    },
    {
      header: "Created Time",
      filter: true,
      field: "createdOnUtc",
      sortable: false,
      dataType: "date",
      body: createdOnUtcBodyTemplate,
      filterElement: createdOnUtcFilterTemplate,
      filterField: "createdOnUtc.date",
      bodyStyle: { width: "15%" },
    },
    {
      header: "Created By",
      filter: true,
      field: "createdBy",
      sortable: false,
      bodyStyle: { width: "10%" },
    },
  ];
};

export default LogsColumns;
