import Settings from ".";

const SettingsRoutes = [
  {
    path: "/settings",
    index: true,
    element: <Settings />,
  },
];

export default SettingsRoutes;
