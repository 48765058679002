import React from 'react'
import '@src/assets/scss/loader.scss'

const Loader = () => {
  return (
      <div class="loader">
          <div class="spinner"></div>
      </div>
  )
}

export default Loader
