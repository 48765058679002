import React, { useState } from "react";
import { Card, CardHeader, Button } from "reactstrap";
import {
  faTruck,
  faForward,
  faHammer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeliverModal from "./DeliverModal";
import ForwardModal from "./ForwardModal";
import DestroyModal from "./DestroyModal";

const ActionToolbar = ({ item }) => {
  const [deliverModelVisibility, setDeliverModelVisibility] = useState(false);
  const [fordardModelVisibility, setFordardModelVisibility] = useState(false);
  const [destroyModelVisibility, setDestroyModelVisibility] = useState(false);

  if (item.status != "Pending") return <></>;

  return (
    <>
      <Card>
        <CardHeader
          className="border-bottom p-1 flex flex-wrap gap-2"
          style={{ justifyContent: "right" }}
        >
          <Button
            type="button"
            color="secondary"
            onClick={() => setFordardModelVisibility(true)}
          >
            <FontAwesomeIcon icon={faForward} className="font-medium-1" />
            &nbsp; Forward
          </Button>
          <Button
            outline
            type="button"
            color="secondary"
            onClick={() => setDestroyModelVisibility(true)}
          >
            <FontAwesomeIcon icon={faHammer} className="font-medium-1" /> &nbsp;
            Destroy
          </Button>
          <Button
            type="button"
            onClick={() => setDeliverModelVisibility(true)}
            color="secondary"
          >
            <FontAwesomeIcon icon={faTruck} className="font-medium-1" /> &nbsp;
            Deliver
          </Button>
        </CardHeader>
      </Card>
      <DeliverModal
        visible={deliverModelVisibility}
        toggle={() => setDeliverModelVisibility(false)}
        item={item}
      />
      <ForwardModal
        visible={fordardModelVisibility}
        toggle={() => setFordardModelVisibility(false)}
        item={item}
      />
      <DestroyModal
        visible={destroyModelVisibility}
        toggle={() => setDestroyModelVisibility(false)}
        item={item}
      />
    </>
  );
};

export default ActionToolbar;
