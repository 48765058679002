import React, { Fragment, memo, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  InputGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import { useGetOrdertQuery } from "@src/redux/stock/order";
import { useParams } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import {
  faCartArrowDown,
  faBoxesPacking,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag } from "primereact/tag";
import { formatDateTime } from "@src/utility/Utils";
import Timeline from "@src/@core/components/timeline";
import ActionToolbar from "./ActionToolbar";

const Details = () => {
  const { id } = useParams();
  const { data, isFetching, error } = useGetOrdertQuery(id);

  const getStatusSeverity = (status) => {
    switch (status) {
      case "pending":
        return "info";
      case "approved":
        return "success";
      case "rejected":
        return "danger";
      default:
        return "warning";
    }
  };

  const getStatusName = (status) => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const [active, setActive] = useState("1");

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };
  if (isFetching) {
    return <Loader />;
  }
  return (
    <Fragment>
      {data ? (
        <Row>
          <Col lg="4" md="4" xs="12">
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle tag="h1" className="fw-bolder">
                  <strong>
                    <span className="text-primary">
                      <FontAwesomeIcon icon={faCartArrowDown} />
                    </span>{" "}
                    <span className="text-primary">Order</span>
                    {"  "}
                    <span className="fw-bolder mb-25">
                      Number ({data.id})
                    </span>{" "}
                  </strong>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="info-container">
                  <br />
                  <>
                    <Row>
                      <Col xl="12" xs="12">
                        <Row tag="dl" className="mb-0">
                          <Col tag="dt" sm="5" className="fw-bolder mb-1">
                            Station:
                          </Col>
                          <Col tag="dd" sm="7" className="mb-1">
                            {data.station}
                          </Col>
                          <Col tag="dt" sm="5" className="fw-bolder mb-1">
                            Order Status:
                          </Col>
                          <Col tag="dd" sm="7" className="mb-1">
                            <InputGroup>
                              <Tag
                                value={data.status}
                                severity={getStatusSeverity(data.status)}
                                className="text-capitalize"
                              />
                            </InputGroup>
                          </Col>
                          {(data.status === "approved" ||
                            data.status === "reversed") && (
                            <>
                              <Col tag="dt" sm="5" className="fw-bolder mb-1">
                                Tag Type:
                              </Col>
                              <Col tag="dd" sm="7" className="mb-1">
                                {data.orderTagTypeName}
                              </Col>
                              <Col tag="dt" sm="5" className="fw-bolder mb-1">
                                Tag:
                              </Col>
                              <Col tag="dd" sm="7" className="mb-1">
                                {data.tag}
                              </Col>
                            </>
                          )}
                          <Col tag="dt" sm="5" className="fw-bolder mb-1">
                            Total Weight:
                          </Col>
                          <Col tag="dd" sm="7" className="mb-1">
                            {data.totalWeight}
                          </Col>
                          <Col tag="dt" sm="5" className="fw-bolder mb-1">
                            Created by:
                          </Col>
                          <Col tag="dd" sm="7" className="mb-1">
                            {data.createdBy}
                          </Col>
                          <Col tag="dt" sm="5" className="fw-bolder mb-1">
                            Created date (UTC):
                          </Col>
                          <Col tag="dd" sm="7" className="mb-1">
                            {data.createdOnUtc &&
                              formatDateTime(new Date(data.createdOnUtc))}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {data.status === "rejected" && (
                      <Row>
                        <hr />
                        <Col tag="dt" sm="12" className="fw-bolder mb-1">
                          Rejection Reason:
                        </Col>
                        <Col tag="dt" sm="12" className="mb-1 text-danger">
                          {data.rejectionReason}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <hr />
                      <Col tag="dt" sm="12" className="fw-bolder mb-1">
                        Comments:
                      </Col>
                      <Col tag="dt" sm="12" className="mb-1">
                        {data.comments}
                      </Col>
                    </Row>
                  </>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8" md="8" xs="12">
            <ActionToolbar order={data} />
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={active === "1"}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <FontAwesomeIcon icon={faBoxesPacking} />
                  Items
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  disabled={
                    !(data.status === "approved" || data.status === "reversed")
                  }
                  active={active === "2"}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <FontAwesomeIcon icon={faBoxesPacking} />
                  Flight Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={active === "3"}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <FontAwesomeIcon icon={faBoxesPacking} />
                  Order History
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="py-50" activeTab={active}>
              <TabPane tabId="1">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Order Items</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="text-capitalize">Item Name</th>
                          <th className="text-capitalize">Quantity Type</th>
                          <th className="text-capitalize">Quantity</th>
                          <th className="text-capitalize">Total Weight</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.orderItems.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.stockItemName}</td>
                            <td>{item.quantityTypeName}</td>
                            <td>{item.quantity}</td>
                            <td>{item.totalWeight}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Flight Info</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col tag="dt" sm="3" className="fw-bolder mb-1">
                        Flight Number:
                      </Col>
                      <Col tag="dd" sm="9" className="mb-1">
                        {data.flightNumber}
                      </Col>

                      <Col tag="dt" sm="3" className="fw-bolder mb-1">
                        Flight Date:
                      </Col>
                      <Col tag="dd" sm="9" className="mb-1">
                        {data.flightDate &&
                          formatDateTime(new Date(data.flightDate))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="3">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Order History</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Timeline
                      data={
                        data.orderHistories?.map((order, index) => ({
                          title: (
                            <span
                              key={index}
                              className={
                                "text-" + getStatusSeverity(order.status)
                              }
                            >
                              {getStatusName(order.status)}
                            </span>
                          ),
                          content: formatDateTime(new Date(order.createdOnUtc)),
                          meta: "Changed by " + order.createdBy,
                          color: getStatusSeverity(order.status),
                        })) || []
                      }
                    />
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <p>{error ? error : "No data available."}</p>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default memo(Details);
