import OrderTags from ".";

const OrderTagRoutes = [
  {
    path: "/stock/order-tags",
    index: true,
    element: <OrderTags />,
  },
];

export default OrderTagRoutes;
