import Items from ".";

const ItemsRoutes = [
  {
    path: "/stock/items",
    index: true,
    element: <Items />,
  },
];

export default ItemsRoutes;
