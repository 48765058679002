import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  CardFooter,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  TabContent,
  TabPane,
  Form,
} from "reactstrap";
import { faTag, faMessage } from "@fortawesome/free-solid-svg-icons";
import { TextInput } from "@src/components/inputs";
import ChipsInput from "@src/components/inputs/ChipsInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import {
  useGetSettingListQuery,
  useUpdateSettingMutation,
} from "@src/redux/general/setting";
import classnames from "classnames";
import { Star, Settings } from "react-feather";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import Loader from "@src/components/shared/Loader";
import { formatPascalCaseToLabel } from "@src/utility/Utils";

const SettingManagement = () => {
  const { data: settingsData, isLoading: isLoadingSettings } =
    useGetSettingListQuery();
  const [updateSetting, { isLoading: isLoadingUpdateSettings }] =
    useUpdateSettingMutation();
  const [activeList, setActiveList] = useState("1");

  const settingsSchema = yup.object().shape({
    name: yup.string().nullable(),
    description: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(settingsSchema),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const groupedSettings = useMemo(() => {
    return settingsData?.reduce((acc, setting) => {
      const category = setting.category || "Uncategorized";
      if (!acc[category]) acc[category] = [];
      acc[category].push(setting);
      return acc;
    }, {});
  }, [settingsData]);

  useEffect(() => {
    // Initialize form values from settingsData
    const initialValues = settingsData?.reduce((acc, setting) => {
      acc[`value_${setting.id}`] =
        setting.valueType === "list" ? setting.value.split(";") : setting.value;
      acc[`description_${setting.id}`] = setting.description || "";
      return acc;
    }, {});

    formMethods.reset(initialValues);
  }, [settingsData, formMethods]);

  const toggleList = (list) => {
    if (activeList !== list) {
      setActiveList(list);
    }
  };

  const handleSaveAll = async (formData) => {
    const settingsToUpdate = Object.keys(groupedSettings).flatMap((category) =>
      groupedSettings[category].map((setting) => ({
        key: setting.key,
        value:
          setting.valueType === "list"
            ? formData[`value_${setting.id}`].join(";") || setting.value
            : formData[`value_${setting.id}`] || setting.value,
        description:
          formData[`description_${setting.id}`] || setting.description,
      }))
    );

    try {
      await updateSetting({ settings: settingsToUpdate }).unwrap();
      openSweetAlert({
        title: "Settings updated successfully!",
        icon: "success",
      });
    } catch (error) {
      openSweetAlert({
        title: "Error updating settings",
        text: error.message || "An error occurred while updating the settings.",
        icon: "error",
      });
    }
  };

  if (isLoadingSettings || isLoadingUpdateSettings) {
    return <Loader />;
  }

  return (
    <Card>
      <CardHeader className="border-bottom">
        <CardTitle tag="h1" className="fw-bolder">
          <strong>
            <span className="text-primary">
              <Settings size={18} className="me-75" />
              Application Settings
            </span>
          </strong>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="info-container">
          <Row>
            <Col md="3" sm="12">
              <ListGroup tag="div">
                {Object.keys(groupedSettings || {}).map((category, index) => (
                  <ListGroupItem
                    key={index}
                    className={classnames("cursor-pointer", {
                      active: activeList === String(index + 1),
                    })}
                    onClick={() => toggleList(String(index + 1))}
                    action
                  >
                    <Star size={18} className="me-75" />
                    <span className="align-middle">{category}</span>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
            <Col className="mt-1" md="9" sm="12">
              <TabContent activeTab={activeList}>
                {Object.keys(groupedSettings || {}).map((category, index) => (
                  <TabPane tabId={String(index + 1)} key={index}>
                    <FormProvider {...formMethods}>
                      <Form>
                        {groupedSettings[category].map((setting, i) => (
                          <Row
                            key={i}
                            className="mb-2 pt-1 pb-2 justify-content-between rounded border align-items-center"
                          >
                            <Col md={6} className="mb-md-0 mb-1">
                              {setting.valueType === "string" ? (
                                <TextInput
                                  twoLines={true}
                                  label={formatPascalCaseToLabel(setting.key)}
                                  name={`value_${setting.id}`}
                                  defaultValue={setting.value}
                                  icon={faTag}
                                />
                              ) : setting.valueType === "list" ? (
                                <ChipsInput
                                  label={formatPascalCaseToLabel(setting.key)}
                                  name={`value_${setting.id}`}
                                />
                              ) : null}
                            </Col>
                            <Col md={6} className="mb-md-0 mb-1">
                              <TextInput
                                twoLines={true}
                                label="Description"
                                name={`description_${setting.id}`}
                                defaultValue={setting.description}
                                icon={faMessage}
                              />
                            </Col>
                          </Row>
                        ))}
                      </Form>
                    </FormProvider>
                  </TabPane>
                ))}
              </TabContent>
            </Col>
          </Row>
        </div>
      </CardBody>
      <CardFooter className="d-flex justify-content-end">
        <Button
          type="button"
          color="primary"
          onClick={formMethods.handleSubmit(handleSaveAll)}
        >
          Save All
        </Button>
      </CardFooter>
    </Card>
  );
};

export default SettingManagement;
