import Logs from ".";

const LogsRoutes = [
  {
    path: "/stock/logs",
    index: true,
    element: <Logs />,
  },
  {
    path: "/stock/logs/:itemId",
    index: true,
    element: <Logs />,
  },
];

export default LogsRoutes;
