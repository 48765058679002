import React, { Fragment } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
} from "reactstrap";
import { ReadonlyInput } from "@src/components/inputs";
import CopyToClipboard from "@src/components/shared/CopyToClipboard";
import { formatDateOnly } from "@src/utility/Utils";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";

const ForwardDetailsModal = ({ modalOpen, toggleModal, selectedForward }) => {
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>Forward Details</ModalHeader>
      <ModalBody>
        {selectedForward ? (
          <>
            <Row>
              <Col md={6}>
                <Row>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Flight Number:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedForward.flightNumber}
                  </Col>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Flight Date:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {formatDateOnly(new Date(selectedForward.flightDate))}
                  </Col>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Forwarded Pieces:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedForward.forwardedPieces}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Destination Station:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedForward.destination}
                  </Col>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Transit Station:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedForward.transitStation}
                  </Col>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Tags:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedForward.tags
                      ? selectedForward.tags.split(";").map((tag, index) => (
                          <Fragment key={index}>
                            <code>{tag.trim()}</code>
                            {index <
                              selectedForward.tags.split(";").length - 1 &&
                              ", "}
                          </Fragment>
                        ))
                      : "No tags available"}
                  </Col>
                </Row>
              </Col>
              <hr></hr>
              <Col tag="dd" sm="12" className="mb-1">
                <div className="mb-1">
                  {selectedForward.comments &&
                  selectedForward.comments.trim() !== "" ? (
                    <>
                      <ReadonlyInput
                        label="Comments"
                        name="comments"
                        icon={faCommentAlt}
                        type="textarea"
                        rows="5"
                        defaultValue={selectedForward.comments}
                      />
                      <CopyToClipboard
                        value={selectedForward.comments}
                        defaultMsg="Click to copy"
                        successMsg="Copied to clipboard!"
                      />
                    </>
                  ) : (
                    <span>No comments available</span>
                  )}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <p>No details available.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ForwardDetailsModal;
