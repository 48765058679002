import { isRejectedWithValue } from '@reduxjs/toolkit'
import openSweetAlert from "@src/components/shared/OpenSweetAlert"

export const rtkQueryErrorHandler = storeAPI => next => action => {
    if (isRejectedWithValue(action)) {
      console.warn('We got a rejected action!');
      if(!action.payload.data) 
        return next(action);
      const errors = action.payload.data.errors || [{code:"Server_error",description:"Internal server error"}];
      openSweetAlert({
        title : "Error occurred",
        icon: "error",
        confirmButtonText : "Oh Ok!",
        confirmButtonClasses : "btn btn-danger",
        html: "<ul>" + errors.map(error => `<li>${error.description}</li>`) + "</ul>"
      });
    }

    return next(action)
  }