import { mainApi } from "../mainApi";

export const itemApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getItems: builder.query({
      query: (filters) => ({
        url: `/stock/stockItem/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "stockItems", id: "LIST" }],
    }),
    addItem: builder.mutation({
      query: (data) => ({
        url: `/stock/stockItem/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "stockItems", id: "LIST" }],
    }),
    updateItem: builder.mutation({
      query: (data) => ({
        url: `/stock/stockItem`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [{ type: "stockItems", id: "LIST" }],
    }),
    getItemList: builder.query({
      query: () => ({
        url: `/stock/stockItem/list`,
        method: "GET",
      }),
    }),
    exportItems: builder.mutation({
      query: (filters) => ({
        url: `/stock/stockItem/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
    settleItemQuantity: builder.mutation({
      query: (data) => ({
        url: `/stock/stockItem/settleQuantity`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "stockItems", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetItemsQuery,
  useAddItemMutation,
  useUpdateItemMutation,
  useGetItemListQuery,
  useExportItemsMutation,
  useSettleItemQuantityMutation,
} = itemApi;
