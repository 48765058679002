import Orders from ".";
import AddOrder from "./add";
import Details from "./details";
import UpdateOrder from "./update";

const OrdersRoutes = [
  {
    path: "/stock/orders",
    index: true,
    element: <Orders />,
  },
  {
    path: "/stock/orders/add",
    index: true,
    element: <AddOrder />,
  },
  {
    path: "/stock/orders/:id",
    index: true,
    element: <Details />,
  },
  {
    path: "/stock/orders/:id/update",
    index: true,
    element: <UpdateOrder />,
  },
];

export default OrdersRoutes;
